import { Component, OnInit } from '@angular/core';
import { DataService } from '../_services/data.service';

@Component({
  selector: 'app-songadd',
  templateUrl: './songadd.component.html',
  styleUrls: ['./songadd.component.scss']
})
export class SongaddComponent implements OnInit {

  alertmessage = '';
  showAlertMessage = false;
  addSongSuccesful = false;

  title: string = '';
  artist: string = '';
  link: string = '';
  bpm: string = '';

  titleReadOnly = false;
  artistReadOnly = false;
  linkReadOnly = false;
  bpmReadOnly = false;

  constructor(private data: DataService) { }

  ngOnInit() {
  }

  // addNew(title: string, artist:string, link: string) {
  //   console.log("addNewSong()");
  //   console.log("title = " + title);
  //   console.log("artist = "+ artist);
  //   console.log("link = " + link);

  //   // this.alertmessage = '<strong>Info!</strong> Song added.';
  //   this.addSongSuccesful = false;
  //   this.toggleAlertMessage("Error occured.");

  //   this.toggleInputFieldsReadOnly();
  // }

  addNewSong() {
    this.hideAlertMessage();

    if (this.hasEmptyFields()) {
      this.showError('Title and artist are both required and cannot be empty.');
    }
    else {
      console.log("addNewSong()");
      console.log("title = " + this.title);
      console.log("artist = "+ this.artist);
      console.log("link = " + this.link);
      console.log("bpm = " + this.bpm);

      this.loadingStart();
      this.data.insertSong(this.title, this.artist, this.link, this.bpm).subscribe(
        data => {
          console.log("POST Request is successful ", data);
          this.showSuccess('Song (' + this.title + ' - ' + this.artist + ') added.');
          this.resetFields();
          this.loadingEnd();
        },
        error => {
          console.log("Error", error);
          this.resetFields();
        }
      )

      // this.showSuccess('Song (' + this.title + ' - ' + this.artist + ') added.');

      // this.resetFields();
    }
  }

  hideAlertMessage() {
    this.showAlertMessage = false;
    this.alertmessage = '';
  }

  showError(message: string) {
    this.addSongSuccesful = false;
    this.showAlertMessage = true;
    this.alertmessage = message;
  }

  showSuccess(message: string) {
    this.addSongSuccesful = true;
    this.showAlertMessage = true;
    this.alertmessage = message;
  }

  toggleInputFieldsReadOnly() {
    this.titleReadOnly = !this.titleReadOnly;
    this.artistReadOnly = !this.artistReadOnly;
    this.linkReadOnly = !this.linkReadOnly;
    this.bpmReadOnly = !this.bpmReadOnly;
  }

  hasEmptyFields() {
    return (this.title == '' || this.artist == '') ? true : false;
  }

  resetFields() {
    this.title = '';
    this.artist = '';
    this.link = '';
    this.bpm = '';
  }

  /* STUFF NEEDED FOR LOADINGINDICATOR */

  showLoadingIndicator = false;
  stillLoading = false;

  loadingStart() {
    console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    // this.showLoadingIndicator = true;
    this.stillLoading = true;
    setTimeout(()=>{    //<<<---    using ()=> syntax
      if (this.stillLoading) this.showLoadingIndicator = true;
      console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    }, this.data.loadingStartDelay); // 100ms delay, so loadingIndicator will only show if loading takes longer than 100ms
  }

  loadingEnd() {
    var timeout = this.data.loadingEndTimeout; // put 6000 for testing purposes to act if still loading content longer than 100ms
    setTimeout(()=>{    //<<<---    using ()=> syntax
      this.showLoadingIndicator = false;
      this.stillLoading = false;
      console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    }, timeout);
  }

  /* STUFF NEEDED FOR LOADINGINDICATOR */
}
