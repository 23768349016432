import { Component, OnInit } from '@angular/core';
import { DataService } from '../_services/data.service';

@Component({
  selector: 'app-bandmemberadd',
  templateUrl: './bandmemberadd.component.html',
  styleUrls: ['./bandmemberadd.component.scss']
})
export class BandmemberaddComponent implements OnInit {

  alertmessage = '';
  showAlertMessage = false;
  addBandmemberSuccesful = false;

  firstname: string = '';
  lastname: string = '';
  email: string = '';
  phone: string = '';
  website: string = '';

  firstnameReadOnly = false;
  lastnameReadOnly = false;
  emailReadOnly = false;
  phoneReadOnly = false;
  websiteReadOnly = false;

  constructor(private data: DataService) { }

  ngOnInit() {
  }

  addNewBandmember() {
    this.hideAlertMessage();

    if (this.hasEmptyFields()) {
      this.showError('Firstname and lastname are both required and cannot be empty.');
    }
    else {
      console.log("addNewBandmember()");
      console.log("firstname = " + this.firstname);
      console.log("lastname = "+ this.lastname);
      console.log("email = "+ this.email);
      console.log("phone = " + this.phone);
      console.log("website = "+ this.website);

      this.loadingStart();
      this.data.insertBandmember(this.firstname, this.lastname, this.email, this.phone, this.website).subscribe(
        data => {
          console.log("POST Request is successful ", data);
          this.showSuccess('Bandmember (' + this.firstname + ' - ' + this.lastname + ') added.');
          this.resetFields();
          this.loadingEnd();
        },
        error => {
          console.log("Error", error);
          this.resetFields();
        }
      )

      // this.showSuccess('Bandmember (' + this.firstname + ' - ' + this.lastname + ') added.');

      // this.resetFields();
    }
  }

  hideAlertMessage() {
    this.showAlertMessage = false;
    this.alertmessage = '';
  }

  showError(message: string) {
    this.addBandmemberSuccesful = false;
    this.showAlertMessage = true;
    this.alertmessage = message;
  }

  showSuccess(message: string) {
    this.addBandmemberSuccesful = true;
    this.showAlertMessage = true;
    this.alertmessage = message;
  }

  toggleInputFieldsReadOnly() {
    this.firstnameReadOnly = !this.firstnameReadOnly;
    this.lastnameReadOnly = !this.lastnameReadOnly;
    this.emailReadOnly = !this.emailReadOnly;
    this.phoneReadOnly = !this.phoneReadOnly;
    this.websiteReadOnly = !this.websiteReadOnly;
  }

  hasEmptyFields() {
    return (this.firstname == '' || this.lastname == '') ? true : false;
  }

  resetFields() {
    this.firstname= '';
    this.lastname = '';
    this.email = '';
    this.phone = '';
    this.website = '';
  }

  /* STUFF NEEDED FOR LOADINGINDICATOR */

  showLoadingIndicator = false;
  stillLoading = false;

  loadingStart() {
    console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    // this.showLoadingIndicator = true;
    this.stillLoading = true;
    setTimeout(()=>{    //<<<---    using ()=> syntax
      if (this.stillLoading) this.showLoadingIndicator = true;
      console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    }, this.data.loadingStartDelay); // 100ms delay, so loadingIndicator will only show if loading takes longer than 100ms
  }

  loadingEnd() {
    var timeout = this.data.loadingEndTimeout; // put 6000 for testing purposes to act if still loading content longer than 100ms
    setTimeout(()=>{    //<<<---    using ()=> syntax
      this.showLoadingIndicator = false;
      this.stillLoading = false;
      console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    }, timeout);
  }

  /* STUFF NEEDED FOR LOADINGINDICATOR */

}
