import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { catchError,tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private dataService: DataService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    console.log("----request----");
    console.log(request);
    // console.log("request.body=" + request.body);
    // console.log("request.method=" + request.method);
    // console.log("request.params=" + request.params);
    // console.log("request.url=" + request.url);
    console.log("--- end of request---");

    // return next.handle(request);

    // this.dataService.showLoadingIndicator = true;
    return next.handle(request)
	    .pipe(
	        tap(event => {
	          if (event instanceof HttpResponse) {
	             
	            console.log(" all looks good");
	            // http response status code
              console.log(event.status);
              // this.dataService.showLoadingIndicator = false;
	          }
	        }, error => {
	   		  	// http response status code
            console.log("----response----");
            console.error("status code:");
            console.error(error.status);
            console.error(error.message);
            console.log("--- end of response---");

	        })
	      )
  }
}
