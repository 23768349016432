import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component'
import { BandmembersComponent } from './bandmembers/bandmembers.component'
import { BandmemberdetailsComponent } from './bandmemberdetails/bandmemberdetails.component'
import { CurrentsetlistComponent } from './currentsetlist/currentsetlist.component'
import { SetlistsComponent } from './setlists/setlists.component'
import { SetlistdetailsComponent } from './setlistdetails/setlistdetails.component'
import { SetlistcontentComponent } from './setlistcontent/setlistcontent.component'
import { SongsComponent } from './songs/songs.component'
import { SongdetailsComponent} from './songdetails/songdetails.component'
import { SetlistitemaddComponent } from './setlistitemadd/setlistitemadd.component'
import { SetlistitemdetailsComponent } from './setlistitemdetails/setlistitemdetails.component'
import { SetlistaddComponent } from './setlistadd/setlistadd.component'
import { SongaddComponent } from './songadd/songadd.component'
import { BandmemberaddComponent } from './bandmemberadd/bandmemberadd.component'
import { AboutComponent } from './about/about.component'
import { AuthGuard } from './auth.guard'

const routes: Routes = [
  {
    path: '',
    component: CurrentsetlistComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'currentsetlist',
    component: CurrentsetlistComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'setlists',
    component: SetlistsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'setlistdetails/:id',
    component: SetlistdetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'setlistcontent/:id',
    component: SetlistcontentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'setlistitemdetails/:id',
    component: SetlistitemdetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'songs',
    component: SongsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'songdetails/:id',
    component: SongdetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'bandmembers',
    component: BandmembersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'bandmemberdetails/:id',
    component: BandmemberdetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'setlistitemadd',
    component: SetlistitemaddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'setlistadd',
    component: SetlistaddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'songadd',
    component: SongaddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'bandmemberadd',
    component: BandmemberaddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'logout',
    component: LoginComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
