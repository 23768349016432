import { Component, OnInit } from '@angular/core';
import { DataService } from '../_services/data.service';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";

@Component({
  selector: 'app-setlistdetails',
  templateUrl: './setlistdetails.component.html',
  styleUrls: ['./setlistdetails.component.scss']
})
export class SetlistdetailsComponent implements OnInit {

  alertmessage = '';
  showAlertMessage = false;
  deleteSuccesful = false;

  setlistId: number;
  setlist$ : Object;

  constructor(private data: DataService, private route: ActivatedRoute, private router: Router) { 
    this.route.params.subscribe(params => {
      this.setlist$ = params.id
      this.setlistId = params.id;
    })
  }

  ngOnInit() {
    this.loadingStart();
    this.data.getSetlistWithItems(this.setlist$).subscribe(
      data => {
        this.setlist$ = data
        this.loadingEnd();
      }
    )
  }

  deleteSetlist() {
    this.hideAlertMessage();

    // if (confirm("Are you sure to delete " + name)) {
    //   console.log("Implement delete functionality here");
    // }


    console.log("deleteSetlist()");
    console.log("setlistId = " + this.setlistId);

    this.loadingStart();
    this.data.deleteSetlist(this.setlistId).subscribe(
      data => {
        console.log("POST Request is successful ", data);

        if (data.Resultcode == 200) {
          this.showSuccess('SetList with id=' + this.setlistId + ' deleted.');
          this.router.navigate(['../setlists']);
        }
        else {
          this.showError(data.Message);
        }
        this.loadingEnd();
      },
      error => {
        console.log("Error", error);
      }
    )
  }

  hideAlertMessage() {
    this.showAlertMessage = false;
    this.alertmessage = '';
  }

  showError(message: string) {
    this.deleteSuccesful = false;
    this.showAlertMessage = true;
    this.alertmessage = message;
  }

  showSuccess(message: string) {
    this.deleteSuccesful = true;
    this.showAlertMessage = true;
    this.alertmessage = message;
  }

  /* STUFF NEEDED FOR LOADINGINDICATOR */

  showLoadingIndicator = false;
  stillLoading = false;

  loadingStart() {
    console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    // this.showLoadingIndicator = true;
    this.stillLoading = true;
    setTimeout(()=>{    //<<<---    using ()=> syntax
      if (this.stillLoading) this.showLoadingIndicator = true;
      console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    }, this.data.loadingStartDelay); // 100ms delay, so loadingIndicator will only show if loading takes longer than 100ms
  }

  loadingEnd() {
    var timeout = this.data.loadingEndTimeout; // put 6000 for testing purposes to act if still loading content longer than 100ms
    setTimeout(()=>{    //<<<---    using ()=> syntax
      this.showLoadingIndicator = false;
      this.stillLoading = false;
      console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    }, timeout);
  }

  /* STUFF NEEDED FOR LOADINGINDICATOR */

}
