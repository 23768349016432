import { Component, OnInit } from '@angular/core';
import { DataService } from '../_services/data.service';

@Component({
  selector: 'app-setlistadd',
  templateUrl: './setlistadd.component.html',
  styleUrls: ['./setlistadd.component.scss']
})
export class SetlistaddComponent implements OnInit {

  alertmessage = '';
  showAlertMessage = false;
  addSetlistSuccesful = false;

  description: string = '';
  currentsetlist: boolean = false;

  titleReadOnly = false;

  constructor(private data: DataService) { }

  ngOnInit() {
  }

  addNewSetlist() {
    this.hideAlertMessage();

    if (this.hasEmptyFields()) {
      this.showError('Description is required and cannot be empty.');
    }
    else {
      console.log("addNewSetlist()");
      console.log("description = " + this.description);
      console.log("current = "+ this.currentsetlist);

      this.loadingStart();
      this.data.insertSetlist(this.description, this.currentsetlist).subscribe(
        data => {
          console.log("POST Request is successful ", data);
          this.showSuccess('Setlist (' + this.description + ' - ' + this.currentsetlist + ') added.');
          this.resetFields();
          this.loadingEnd();
        },
        error => {
          console.log("Error", error);
          this.resetFields();
        }
      )

      // this.showSuccess('Setlist (' + this.title + ' - ' + this.currentsetlist + ') added.');

      // this.resetFields();
    }
  }

  hideAlertMessage() {
    this.showAlertMessage = false;
    this.alertmessage = '';
  }

  showError(message: string) {
    this.addSetlistSuccesful = false;
    this.showAlertMessage = true;
    this.alertmessage = message;
  }

  showSuccess(message: string) {
    this.addSetlistSuccesful = true;
    this.showAlertMessage = true;
    this.alertmessage = message;
  }

  toggleInputFieldsReadOnly() {
    this.titleReadOnly = !this.titleReadOnly;
  }

  hasEmptyFields() {
    return (this.description == '') ? true : false;
  }

  resetFields() {
    this.description = '';
    this.currentsetlist = false;
  }

  /* STUFF NEEDED FOR LOADINGINDICATOR */

  showLoadingIndicator = false;
  stillLoading = false;

  loadingStart() {
    console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    // this.showLoadingIndicator = true;
    this.stillLoading = true;
    setTimeout(()=>{    //<<<---    using ()=> syntax
      if (this.stillLoading) this.showLoadingIndicator = true;
      console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    }, this.data.loadingStartDelay); // 100ms delay, so loadingIndicator will only show if loading takes longer than 100ms
  }

  loadingEnd() {
    var timeout = this.data.loadingEndTimeout; // put 6000 for testing purposes to act if still loading content longer than 100ms
    setTimeout(()=>{    //<<<---    using ()=> syntax
      this.showLoadingIndicator = false;
      this.stillLoading = false;
      console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    }, timeout);
  }

  /* STUFF NEEDED FOR LOADINGINDICATOR */

}
