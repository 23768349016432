import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // return true;
    
    if (localStorage.getItem('currentUser')) {
      // user was logged in at least once so return true

      var currentUser = JSON.parse(localStorage.getItem('currentUser'));

      var dateNow = new Date();
      var expiryDate  = new Date(currentUser.expiryDateString);
      if (expiryDate.getTime() > dateNow.getTime()) {
        // console.log("session should be active and not expired yet");

        // session is within 30min >> renew session?
        this.storeSessionInLocalStorage(currentUser);

        return true;
      }
      else {
        console.log("session expired >> re-login");
        // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        this.router.navigate(['/login']);
        return false;
      }

      // return true;
    }

    // not logged in so redirect to login page with the return url
    // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    this.router.navigate(['/login']);
    return false;
  }

  storeSessionInLocalStorage(currentUser: any) {
    var expiryDateNew = new Date();
    var expirationTime = 1800000; // 30 minutes
    // var expirationTime = 1000; // test with 1 second
    // var expirationTime = 30000; // test with 1/2 minute

    expiryDateNew.setTime(expiryDateNew.getTime() + expirationTime);
    var expiryDateNewString = expiryDateNew.toString();

    console.log("Page action within " + (expirationTime >= 30000 ? ((expirationTime/1000)/60) + " minutes" : ((expirationTime/1000)) + " seconds" ) + " >> update session expiryDate")
    console.log("Old expiryDate = " + currentUser.expiryDateString);
    console.log("New expiryDate = " + expiryDateNew.toString());

    localStorage.setItem('currentUser', JSON.stringify({ token: currentUser.token, expiryDateString: expiryDateNewString, name: currentUser.name }));
  }
}
