import { Component, OnInit } from '@angular/core';
import { DataService } from '../_services/data.service';

@Component({
  selector: 'app-setlistitemadd',
  templateUrl: './setlistitemadd.component.html',
  styleUrls: ['./setlistitemadd.component.scss']
})
export class SetlistitemaddComponent implements OnInit {

  alertmessage = '';
  showAlertMessage = false;
  addSetlistitemSuccesful = false;

  selectedSongId: number = 0;
  selectedSetlistId: number = 0;

  songs: Object;
  setlists: Object;

  constructor(private data: DataService) { }

  ngOnInit() {
    // this.data.getSongs().subscribe(
    //   data => this.songs = data
    // )
    // this.data.getSetlists().subscribe(
    //   data => this.setlists = data
    // )

    this.loadingStart();
    this.data.getSongs().subscribe(
      songsData => {
        this.songs = songsData

        this.data.getSetlists().subscribe(
          setlistsData => {
            this.setlists = setlistsData
            this.loadingEnd();
          }
        )
      }
    )
  }

  addNewSetlistitem() {
    this.hideAlertMessage();

    if (this.noSelectionFound()) {
      this.showError('You must select a song and setlist.');
    }
    else {
      console.log("addNewSetlistitem()");
      console.log("selectedSongId = " + this.selectedSongId);
      console.log("selectedSetlistId = "+ this.selectedSetlistId);

      var foundSongIndex = Object.keys(this.songs).find(k => this.songs[k].Id === this.selectedSongId);
      var foundSong = "(" + this.songs[foundSongIndex].Title + ", " + this.songs[foundSongIndex].Artist + ")";
      console.log("found song: " + foundSong);

      var foundSetlistIndex = Object.keys(this.setlists).find(k => this.setlists[k].Id === this.selectedSetlistId);
      var foundSetlist = "(" + this.setlists[foundSetlistIndex].Description + ")";
      console.log("found setlist: " + foundSetlist);

      this.loadingStart();
      this.data.insertSetlistItem(this.selectedSongId, this.selectedSetlistId).subscribe(
        data => {
          console.log("POST Request is successful ", data);

          if (data.Resultcode == 200) {
            this.showSuccess('Setlistitem ' + foundSong + " - " + foundSetlist +' added.');
            this.resetFields();
          }
          else {
            this.showError(data.Message);
          }

          this.loadingEnd();
          // this.showSuccess('Setlistitem ' + foundSong + " - " + foundSetlist +' added.');
          // this.resetFields();
        },
        error => {
          console.log("Error", error);
          this.resetFields();
        }
      )

      // this.showSuccess('Setlistitem ' + foundSong + " - " + foundSetlist +' added.');

      // this.resetFields();
    }
  }

  getSelectedSong(element, index, array) {
    return (element = this.selectedSongId)
  }

  hideAlertMessage() {
    this.showAlertMessage = false;
    this.alertmessage = '';
  }

  showError(message: string) {
    this.addSetlistitemSuccesful = false;
    this.showAlertMessage = true;
    this.alertmessage = message;
  }

  showSuccess(message: string) {
    this.addSetlistitemSuccesful = true;
    this.showAlertMessage = true;
    this.alertmessage = message;
  }

  noSelectionFound() {
    return (this.selectedSongId == 0 || this.selectedSetlistId == 0) ? true : false;
  }

  resetFields() {
    this.selectedSongId = 0;
    this.selectedSetlistId = 0;
  }

  /* STUFF NEEDED FOR LOADINGINDICATOR */

  showLoadingIndicator = false;
  stillLoading = false;

  loadingStart() {
    console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    // this.showLoadingIndicator = true;
    this.stillLoading = true;
    setTimeout(()=>{    //<<<---    using ()=> syntax
      if (this.stillLoading) this.showLoadingIndicator = true;
      console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    }, this.data.loadingStartDelay); // 100ms delay, so loadingIndicator will only show if loading takes longer than 100ms
  }

  loadingEnd() {
    var timeout = this.data.loadingEndTimeout; // put 6000 for testing purposes to act if still loading content longer than 100ms
    setTimeout(()=>{    //<<<---    using ()=> syntax
      this.showLoadingIndicator = false;
      this.stillLoading = false;
      console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    }, timeout);
  }

  /* STUFF NEEDED FOR LOADINGINDICATOR */

}
