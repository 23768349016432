import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericResponse } from '../_interfaces/generic-response';
import { Song } from '../_interfaces/song';
import { Bandmember } from '../_interfaces/bandmember';
import { Setlistitem } from '../_interfaces/setlistitem';

@Injectable({
  providedIn: 'root'
})

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type':  'application/json'
//   })
// };

// export let httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type':  'application/json'
//   })
// };

export class DataService {

  public loadingStartDelay: number = 100; // 100ms delay, so loadingIndicator will only show if loading takes longer than 100ms
  public loadingEndTimeout: number = 0; // put 6000 for testing purposes to act if still loading content longer than 100ms

  private baseUrl: string = "http://wcfservices.mdwdevelopment.com/BandSetListService.svc";
  // private baseUrl: string = "http://localhost:55257/BandSetListService.svc";

  constructor(private http: HttpClient) { }

  getBandmembers() {
    return this.http.get(this.baseUrl + '/sql/json/bandmembers/')
  }

  getBandmember(bandmemberId): Observable<Bandmember> {
    return this.http.get<Bandmember>(this.baseUrl + '/sql/json/bandmembers?id='+bandmemberId)
  }

  insertBandmember(firstname, lastname, email, phone, website) {
    // POST request doesn't work because of CORS, still have to fix it. In the mean time use GET request below
    return this.http.get(this.baseUrl + '/sql/json/bandmembers/INSERT?firstname='+firstname+'&lastname='+lastname+'&email='+email+'&phone='+phone+'&website='+website)
  }

  updateBandmember(id, firstname, lastname, email, phone, website): Observable<GenericResponse> {
    // POST request doesn't work because of CORS, still have to fix it. In the mean time use GET request below
    return this.http.get<GenericResponse>(this.baseUrl + '/sql/json/bandmembers/UPDATE?id='+id+'&firstname='+firstname+'&lastname='+lastname+'&email='+email+'&phone='+phone+'&website='+website)
  }

  deleteBandmember(bandmemberId): Observable<GenericResponse> {
    // POST request doesn't work because of CORS, still have to fix it. In the mean time use GET request below
    return this.http.get<GenericResponse>(this.baseUrl + '/sql/json/bandmembers/DELETE?id='+bandmemberId)
  }

  getSongs() {
    return this.http.get(this.baseUrl + '/sql/json/songs/')
  }

  getSong(songId): Observable<Song> {
    return this.http.get<Song>(this.baseUrl + '/sql/json/songs?id='+songId)
  }

  insertSong(title, artist, link, bpm) {
    // POST request doesn't work because of CORS, still have to fix it. In the mean time use GET request below
    // return this.http.post('http://wcfservices.mdwdevelopment.com/BandSetListService.svc/sql/json/songs/INSERT/',
    // {
    //   "Id":0,
    //   "Title":title,
    //   "Artist":artist,
    //   "Link":link
    // },
    // {
    //   headers: {'Content-Type':'application/json'}
    // })
    return this.http.get(this.baseUrl + '/sql/json/songs/INSERT?title='+title+'&artist='+artist+'&link='+link+'&bpm='+bpm)
  }

  updateSong(id, title, artist, link, bpm): Observable<GenericResponse> {
    // POST request doesn't work because of CORS, still have to fix it. In the mean time use GET request below
    return this.http.get<GenericResponse>(this.baseUrl + '/sql/json/songs/UPDATE?id='+id+'&title='+title+'&artist='+artist+'&link='+link+'&bpm='+bpm)
  }

  deleteSong(songId): Observable<GenericResponse> {
    // POST request doesn't work because of CORS, still have to fix it. In the mean time use GET request below
    return this.http.get<GenericResponse>(this.baseUrl + '/sql/json/songs/DELETE?id='+songId)
  }

  getSetlists() {
    return this.http.get(this.baseUrl + '/sql/json/setlists/')
  }

  getCurrentSetlistsWithItems() {
    return this.http.get(this.baseUrl + '/sql/json/setlistwithitems/current/')
  }

  getSetlistWithItems(setlistId) {
    return this.http.get(this.baseUrl + '/sql/json/setlistwithitems?id='+setlistId)
  }

  insertSetlist(description, makecurrent) {
    // POST request doesn't work because of CORS, still have to fix it. In the mean time use GET request below
    return this.http.get(this.baseUrl + '/sql/json/setlists/INSERT?description='+description+'&makecurrent='+makecurrent)
  }

  deleteSetlist(setlistId): Observable<GenericResponse> {
    // POST request doesn't work because of CORS, still have to fix it. In the mean time use GET request below
    return this.http.get<GenericResponse>(this.baseUrl + '/sql/json/setlists/DELETE?id='+setlistId)
  }

  getSetlistItem(setlistItemId): Observable<Setlistitem> {
    return this.http.get<Setlistitem>(this.baseUrl + '/sql/json/bandsetlistitems?id='+setlistItemId)
  }

  insertSetlistItem(songId, setlistId): Observable<GenericResponse> {
    return this.http.get<GenericResponse>(this.baseUrl + '/sql/json/bandsetlistitems/INSERT?songId='+songId+'&setlistId='+setlistId)
  }

  updateSetlistItem(setlistitemId, notes): Observable<GenericResponse> {
    return this.http.get<GenericResponse>(this.baseUrl + '/sql/json/bandsetlistitems/UPDATE?id='+setlistitemId+'&notes='+notes)
  }

  deleteSetlistItem(setlistItemId) {
    // POST request doesn't work because of CORS, still have to fix it. In the mean time use GET request below
    return this.http.get(this.baseUrl + '/sql/json/bandsetlistitems/DELETE?id='+setlistItemId)
  }
}


