import { Component, OnInit } from '@angular/core';
import { DataService } from '../_services/data.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { Setlistitem } from '../_interfaces/setlistitem';

@Component({
  selector: 'app-setlistitemdetails',
  templateUrl: './setlistitemdetails.component.html',
  styleUrls: ['./setlistitemdetails.component.scss']
})
export class SetlistitemdetailsComponent implements OnInit {

  alertmessage = '';
  showAlertMessage = false;
  deleteSuccesful = false;

  setlistitemId: number;
  // setlistitem$ : Object;
  setlistitem$ : Setlistitem;
  setlistitemBackup: Setlistitem;

  editModeActivated = false;

  constructor(private data: DataService, private route: ActivatedRoute, private router: Router) { 
    this.route.params.subscribe(params => {
      this.setlistitem$ = params.id
      this.setlistitemId = params.id;
    })
  }

  ngOnInit() {
    this.loadingStart();
    this.data.getSetlistItem(this.setlistitem$).subscribe(
      data => {
        this.setlistitem$ = data

        this.setlistitem$.Notes = this.setlistitem$.Notes.replace(/<br>/g,'\r\n');
        this.loadingEnd();
      }
    )
  }

  editSetlistitem() {
    this.hideAlertMessage();
    this.editModeActivated = true;
    this.setlistitemBackup = Object.assign({}, this.setlistitem$);
  }

  saveSetlistitem() {
    console.log(this.setlistitem$.Id);
    console.log(this.setlistitem$.SortOrder);
    console.log(this.setlistitem$.Notes);

    var notesFormatted = this.setlistitem$.Notes.replace(/(?:\r\n|\r|\n)/g, '<br>');
    notesFormatted = notesFormatted.replace(/&/g, 'en');
    console.log("formatted? " + notesFormatted);

    console.log("saveSetlistitem()");
    console.log("setlistitemId = " + this.setlistitemId);

    this.loadingStart();
    this.data.updateSetlistItem(this.setlistitem$.Id, notesFormatted).subscribe(
      data => {this.setlistitem$.Notes
        console.log("POST Request is successful ", data);

        if (data.Resultcode == 200) {
          this.showSuccess(data.Message);
          // this.router.navigate(['../songs']);
        }
        else {
          this.showError(data.Message);
        }

        this.loadingEnd();
        // this.showSuccess('Song with id=' + this.songId + ' deleted.');
        // this.router.navigate(['../songs']);
      },
      error => {
        console.log("Error", error);
      }
    )

    this.editModeActivated = false;
  }

  cancelEditSetlistitem() {
    this.hideAlertMessage();
    this.editModeActivated = false;
    this.setlistitem$ = this.setlistitemBackup;
  }

  deleteSetlistitem() {
    this.hideAlertMessage();

    // if (confirm("Are you sure to delete " + name)) {
    //   console.log("Implement delete functionality here");
    // }


    console.log("deleteSetlistitem()");
    console.log("setlistitemId = " + this.setlistitemId);

    this.loadingStart();
    this.data.deleteSetlistItem(this.setlistitemId).subscribe(
      data => {
        console.log("POST Request is successful ", data);
        this.showSuccess('SetlistItem with id=' + this.setlistitemId + ' deleted.');
        this.loadingEnd();
        this.router.navigate(['../']); //back to previous page??
      },
      error => {
        console.log("Error", error);
      }
    )
  }

  hideAlertMessage() {
    this.showAlertMessage = false;
    this.alertmessage = '';
  }

  showError(message: string) {
    this.deleteSuccesful = false;
    this.showAlertMessage = true;
    this.alertmessage = message;
  }

  showSuccess(message: string) {
    this.deleteSuccesful = true;
    this.showAlertMessage = true;
    this.alertmessage = message;
  }

  /* STUFF NEEDED FOR LOADINGINDICATOR */

  showLoadingIndicator = false;
  stillLoading = false;

  loadingStart() {
    console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    // this.showLoadingIndicator = true;
    this.stillLoading = true;
    setTimeout(()=>{    //<<<---    using ()=> syntax
      if (this.stillLoading) this.showLoadingIndicator = true;
      console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    }, this.data.loadingStartDelay); // 100ms delay, so loadingIndicator will only show if loading takes longer than 100ms
  }

  loadingEnd() {
    var timeout = this.data.loadingEndTimeout; // put 6000 for testing purposes to act if still loading content longer than 100ms
    setTimeout(()=>{    //<<<---    using ()=> syntax
      this.showLoadingIndicator = false;
      this.stillLoading = false;
      console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    }, timeout);
  }

  /* STUFF NEEDED FOR LOADINGINDICATOR */

}
