import { Component, OnInit } from '@angular/core';
import { DataService } from '../_services/data.service';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { Bandmember } from '../_interfaces/bandmember';

@Component({
  selector: 'app-bandmemberdetails',
  templateUrl: './bandmemberdetails.component.html',
  styleUrls: ['./bandmemberdetails.component.scss']
})
export class BandmemberdetailsComponent implements OnInit {

  alertmessage = '';
  showAlertMessage = false;
  deleteSuccesful = false;

  bandmemberId: number;
  // bandmember$ : Object;

  bandmember$ : Bandmember;
  bandmemberBackup: Bandmember;

  editModeActivated = false;

  constructor(private data: DataService, private route: ActivatedRoute, private router: Router) { 
    this.route.params.subscribe(params => {
      this.bandmember$ = params.id
      this.bandmemberId = params.id;
    })
  }

  ngOnInit() {
    this.loadingStart();

    this.data.getBandmember(this.bandmemberId).subscribe(
      data => {
        this.bandmember$ = data
        this.loadingEnd();
      }
    )
  }

  editBandmember() {
    this.hideAlertMessage();
    this.editModeActivated = true;
    this.bandmemberBackup = Object.assign({}, this.bandmember$);
  }

  saveBandmember() {
    console.log(this.bandmember$.Id);
    console.log(this.bandmember$.FirstName);
    console.log(this.bandmember$.LastName);
    console.log(this.bandmember$.Email);
    console.log(this.bandmember$.Phone);
    console.log(this.bandmember$.Website);

    this.loadingStart();
    this.data.updateBandmember(this.bandmember$.Id, this.bandmember$.FirstName, this.bandmember$.LastName, this.bandmember$.Email, this.bandmember$.Phone, this.bandmember$.Website).subscribe(
      data => {

        if (data.Resultcode == 200) {
          console.log("POST Request is successful ", data);
          this.showSuccess(data.Message);
        }
        else {
          this.showError(data.Message);
        }

        this.loadingEnd();
      },
      error => {
        console.log("Error", error);
      }
    )

    this.editModeActivated = false;
  }

  cancelEditBandmember() {
    this.hideAlertMessage();
    this.editModeActivated = false;
    this.bandmember$ = this.bandmemberBackup;
  }

  deleteBandmember() {
    this.hideAlertMessage();

    // if (confirm("Are you sure to delete " + name)) {
    //   console.log("Implement delete functionality here");
    // }


    console.log("deleteBandmember()");
    console.log("bandmemberId = " + this.bandmemberId);

    this.loadingStart();
    this.data.deleteBandmember(this.bandmemberId).subscribe(
      data => {
        console.log("POST Request is successful ", data);

        if (data.Resultcode == 200) {
          this.showSuccess(data.Message);
          this.router.navigate(['../bandmembers']);
        }
        else {
          this.showError(data.Message);
        }

        this.loadingEnd();
        // this.showSuccess('BandMember with id=' + this.bandmemberId + ' deleted.');
        // this.router.navigate(['../bandmembers']);
      },
      error => {
        console.log("Error", error);
      }
    )
  }

  hideAlertMessage() {
    this.showAlertMessage = false;
    this.alertmessage = '';
  }

  showError(message: string) {
    this.deleteSuccesful = false;
    this.showAlertMessage = true;
    this.alertmessage = message;
  }

  showSuccess(message: string) {
    this.deleteSuccesful = true;
    this.showAlertMessage = true;
    this.alertmessage = message;
  }

  /* STUFF NEEDED FOR LOADINGINDICATOR */

  showLoadingIndicator = false;
  stillLoading = false;

  loadingStart() {
    console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    // this.showLoadingIndicator = true;
    this.stillLoading = true;
    setTimeout(()=>{    //<<<---    using ()=> syntax
      if (this.stillLoading) this.showLoadingIndicator = true;
      console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    }, this.data.loadingStartDelay); // 100ms delay, so loadingIndicator will only show if loading takes longer than 100ms
  }

  loadingEnd() {
    var timeout = this.data.loadingEndTimeout; // put 6000 for testing purposes to act if still loading content longer than 100ms
    setTimeout(()=>{    //<<<---    using ()=> syntax
      this.showLoadingIndicator = false;
      this.stillLoading = false;
      console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    }, timeout);
  }

  /* STUFF NEEDED FOR LOADINGINDICATOR */

}
