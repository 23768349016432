import { Component, OnInit } from '@angular/core';
import { DataService } from '../_services/data.service';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { Song } from '../_interfaces/song';

@Component({
  selector: 'app-songdetails',
  templateUrl: './songdetails.component.html',
  styleUrls: ['./songdetails.component.scss']
})
export class SongdetailsComponent implements OnInit {

  alertmessage = '';
  showAlertMessage = false;
  deleteSuccesful = false;

  songId: number;

  song$: Song;
  songBackup: Song;

  editModeActivated = false;

  constructor(private data: DataService, private route: ActivatedRoute, private router: Router) {
    // this.route.params.subscribe(params => this.song$ = params.id)
    this.route.params.subscribe(params => {
      this.songId = params.id;
      this.song$ = params.id;
    });
  }

  ngOnInit() {
    this.loadingStart();
    this.data.getSong(this.songId).subscribe(
      data => {
        this.song$ = data
        this.loadingEnd();
      }
    )
  }

  editSong() {
    this.hideAlertMessage();
    this.editModeActivated = true;
    this.songBackup = Object.assign({}, this.song$);
  }

  saveSong() {
    console.log(this.song$.Id);
    console.log(this.song$.Title);
    console.log(this.song$.Artist);
    console.log(this.song$.Link);
    console.log(this.song$.Bpm);

    console.log("saveSong()");
    console.log("songId = " + this.songId);

    this.loadingStart();
    this.data.updateSong(this.song$.Id, this.song$.Title, this.song$.Artist, this.song$.Link, this.song$.Bpm).subscribe(
      data => {
        console.log("POST Request is successful ", data);

        if (data.Resultcode == 200) {
          this.showSuccess(data.Message);
          // this.router.navigate(['../songs']);
        }
        else {
          this.showError(data.Message);
        }

        this.loadingEnd();
        // this.showSuccess('Song with id=' + this.songId + ' deleted.');
        // this.router.navigate(['../songs']);
      },
      error => {
        console.log("Error", error);
      }
    )

    this.editModeActivated = false;
  }

  cancelEditSong() {
    this.hideAlertMessage();
    this.editModeActivated = false;
    this.song$ = this.songBackup;
  }

  deleteSong() {
    this.hideAlertMessage();

    // if (confirm("Are you sure to delete " + name)) {
    //   console.log("Implement delete functionality here");
    // }


    console.log("deleteSong()");
    console.log("songId = " + this.songId);

    this.loadingStart();
    this.data.deleteSong(this.songId).subscribe(
      data => {
        console.log("POST Request is successful ", data);

        if (data.Resultcode == 200) {
          this.showSuccess(data.Message);
          this.router.navigate(['../songs']);
        }
        else {
          this.showError(data.Message);
        }

        this.loadingEnd();
        // this.showSuccess('Song with id=' + this.songId + ' deleted.');
        // this.router.navigate(['../songs']);
      },
      error => {
        console.log("Error", error);
      }
    )
  }

  hideAlertMessage() {
    this.showAlertMessage = false;
    this.alertmessage = '';
  }

  showError(message: string) {
    this.deleteSuccesful = false;
    this.showAlertMessage = true;
    this.alertmessage = message;
  }

  showSuccess(message: string) {
    this.deleteSuccesful = true;
    this.showAlertMessage = true;
    this.alertmessage = message;
  }

  /* STUFF NEEDED FOR LOADINGINDICATOR */

  showLoadingIndicator = false;
  stillLoading = false;

  loadingStart() {
    console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    // this.showLoadingIndicator = true;
    this.stillLoading = true;
    setTimeout(()=>{    //<<<---    using ()=> syntax
      if (this.stillLoading) this.showLoadingIndicator = true;
      console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    }, this.data.loadingStartDelay); // 100ms delay, so loadingIndicator will only show if loading takes longer than 100ms
  }

  loadingEnd() {
    var timeout = this.data.loadingEndTimeout; // put 6000 for testing purposes to act if still loading content longer than 100ms
    setTimeout(()=>{    //<<<---    using ()=> syntax
      this.showLoadingIndicator = false;
      this.stillLoading = false;
      console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    }, timeout);
  }

  /* STUFF NEEDED FOR LOADINGINDICATOR */

}
