import { Component, OnInit } from '@angular/core';
import { DataService } from '../_services/data.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-bandmembers',
  templateUrl: './bandmembers.component.html',
  styleUrls: ['./bandmembers.component.scss']
})
export class BandmembersComponent implements OnInit {

  bandmembers$: Object;

  constructor(private data: DataService) { }

  ngOnInit() {
    this.loadingStart();
    this.data.getBandmembers().subscribe(
      data => {
        this.bandmembers$ = data
        this.loadingEnd();
      }
    )
  }

  /* STUFF NEEDED FOR LOADINGINDICATOR */

  showLoadingIndicator = false;
  stillLoading = false;

  loadingStart() {
    console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    // this.showLoadingIndicator = true;
    this.stillLoading = true;
    setTimeout(()=>{    //<<<---    using ()=> syntax
      if (this.stillLoading) this.showLoadingIndicator = true;
      console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    }, this.data.loadingStartDelay); // 100ms delay, so loadingIndicator will only show if loading takes longer than 100ms
  }

  loadingEnd() {
    var timeout = this.data.loadingEndTimeout; // put 6000 for testing purposes to act if still loading content longer than 100ms
    setTimeout(()=>{    //<<<---    using ()=> syntax
      this.showLoadingIndicator = false;
      this.stillLoading = false;
      console.log("this.showLoadingIndicator=" + this.showLoadingIndicator);
    }, timeout);
  }

  /* STUFF NEEDED FOR LOADINGINDICATOR */

}
