import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BandmembersComponent } from './bandmembers/bandmembers.component';
import { SetlistsComponent } from './setlists/setlists.component';
import { BandmemberdetailsComponent } from './bandmemberdetails/bandmemberdetails.component';

import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './_services/interceptor.service';

import { FormsModule } from '@angular/forms'

import { SongsComponent } from './songs/songs.component';
import { CurrentsetlistComponent } from './currentsetlist/currentsetlist.component';
import { SetlistdetailsComponent } from './setlistdetails/setlistdetails.component';
import { SongdetailsComponent } from './songdetails/songdetails.component';
import { SongaddComponent } from './songadd/songadd.component';
import { SetlistaddComponent } from './setlistadd/setlistadd.component';
import { SetlistitemaddComponent } from './setlistitemadd/setlistitemadd.component';
import { BandmemberaddComponent } from './bandmemberadd/bandmemberadd.component';
import { AboutComponent } from './about/about.component';
import { SetlistcontentComponent } from './setlistcontent/setlistcontent.component';
import { SetlistitemdetailsComponent } from './setlistitemdetails/setlistitemdetails.component';
import { LoginComponent } from './login/login.component';

import { SelfBuildingSquareSpinnerModule } from 'angular-epic-spinners';
import { LoadingindicatorComponent } from './loadingindicator/loadingindicator.component';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    BandmembersComponent,
    SetlistsComponent,
    BandmemberdetailsComponent,
    SongsComponent,
    CurrentsetlistComponent,
    SetlistdetailsComponent,
    SongdetailsComponent,
    SongaddComponent,
    SetlistaddComponent,
    SetlistitemaddComponent,
    BandmemberaddComponent,
    AboutComponent,
    SetlistcontentComponent,
    SetlistitemdetailsComponent,
    LoginComponent,
    LoadingindicatorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    SelfBuildingSquareSpinnerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
