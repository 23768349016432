import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  alertmessage = '';
  showAlertMessage = false;
  loginSuccesful = false;

  username: string = '';
  password: string = '';

  constructor(private router: Router) { }

  ngOnInit() {
    console.log("LoginComponent.ngOnInit()");
    localStorage.clear();
  }

  login() {
    console.log("login()");
    console.log("username = " + this.username);
    console.log("password = "+ this.password);

    // TEST //
    // var user = JSON.parse(localStorage.getItem('currentUser'));
    // console.log("getItem(currentUser) = " + user);
    // user != null ? console.log("user.token = " + user.token) : console.log("user == null");

    var tokenStatic = "1234567890";
    // var user1 = 'matt';
    // if (this.username == user1 && this.password == user1) {
    //   console.log("login succesful");

    //   var expiryDate = new Date();
    //   expiryDate.setTime(expiryDate.getTime() + 1800000); // plus 30 minutes
    //   // expiryDate.setTime(expiryDate.getTime() + 1000); // test with 1 second
    //   var expiryDateString = expiryDate.toString();

    //   localStorage.setItem('currentUser', JSON.stringify({ token: tokenStatic, expiryDateString: expiryDateString, name: user1 }));
    //   // localStorage.setItem("", "")
    //   this.router.navigate(['../currentsetlist']);
    // }

    var userArray = ["matt", "rudo", "jeroen", "mark", "yara"];
    userArray.forEach(element => {
      if (this.username == element && this.password == element) {
        console.log("login succesful");

        // var expiryDate = new Date();
        // expiryDate.setTime(expiryDate.getTime() + 1800000); // plus 30 minutes
        // // expiryDate.setTime(expiryDate.getTime() + 1000); // test with 1 second
        // // expiryDate.setTime(expiryDate.getTime() + 60000); // test with 1 minute
        // var expiryDateString = expiryDate.toString();

        // localStorage.setItem('currentUser', JSON.stringify({ token: tokenStatic, expiryDateString: expiryDateString, name: element }));

        this.storeSessionInLocalStorage(tokenStatic, element);

        // localStorage.setItem("", "")
        this.router.navigate(['../currentsetlist']);
      }
    });

    // login unsuccesful
    this.showError("Username or password incorrect!");
  }

  storeSessionInLocalStorage(tokenStatic:string, user:string) {
    var expiryDate = new Date();
    var expirationTime = 1800000; // 30 minutes
    // var expirationTime = 1000; // test with 1 second
    // var expirationTime = 30000; // test with 1/2 minute

    expiryDate.setTime(expiryDate.getTime() + expirationTime);
    var expiryDateString = expiryDate.toString();

    localStorage.setItem('currentUser', JSON.stringify({ token: tokenStatic, expiryDateString: expiryDateString, name: user }));
  }

  hideAlertMessage() {
    this.showAlertMessage = false;
    this.alertmessage = '';
  }

  showError(message: string) {
    this.loginSuccesful = false;
    this.showAlertMessage = true;
    this.alertmessage = message;
  }

  showSuccess(message: string) {
    this.loginSuccesful = true;
    this.showAlertMessage = true;
    this.alertmessage = message;
  }

  resetFields() {
    this.username = '';
    this.password = '';
  }

}
